.landing-page1-container {
    background-image: url("../../../assets/vietnam/landing-page1-bg.png");
    background-size: 100% 100%;
}

.landing-page2-container {
    background-image: url("../../../assets/vietnam/landing-page2-bg.png");
    background-size: 100% 100%;
}

.ksl {
    font-size: 55px;
    font-family: sans-serif,
        "CeraPRO-Black";
    line-height: 80%;
}

.smkm {
    font-family: "CeraGR-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
}

.background-image1 {
    background-image: url("../../../assets/vietnam/landing-page3-bg1.png");
    background-size: 100% 100%;
}

.background-image2 {
    background-image: url("../../../assets/vietnam/landing-page3-bg2.png");
    background-size: 100% 100%;
}

.peri-border {
    border-image: linear-gradient(
            to left,
            grey,
            white,
            grey
        )
        1;
}

.peri-active {
    color: white;
    background-color: #C32D87;
    border-radius: 25px;
    text-transform: uppercase;
}
