.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  transform: translateY(100%); /* Initially hidden */
}

.popup.open {
  transform: translateY(0); /* Fully visible */
}

.popup.closed {
  transform: translateY(100%); /* Hidden */
}

.bigger {
  padding: 12px 3px;
  border-radius: 10px;
  margin: auto;
}

@keyframes lineAppear {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.line {
  animation: lineAppear 0.5s ease forwards;
}
