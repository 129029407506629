.peri-ovule {
    outline: 550px solid
        rgba(
            178,
            178,
            178,
            0.7
        );
}

#capture-button {
    position: absolute;
    left: 5% !important;
    top: 8% !important;
    height: 84% !important;
    width: 90% !important;
    /* border: 2px solid red; */
    z-index: 3;
}
