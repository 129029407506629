.tag-name {
    z-index: 6;
    color: white;
    background-color: #5fbadd;
    font-size: 16px;
    letter-spacing: 1px;
    user-select: none;
}

/* resizer */
.popup-annotation {
    z-index: 7;
    background-color: #5fbadd;
    /* text-align: center ;  */
    /* min-height: 150px;
  min-width: 300px;
  max-height: 300px;
  max-width: 600px; */
}

/*Drgable */

.popup-annotation {
    position: absolute;
    /* border: 5px solid #d3d3d3!important; */

    /*resize: both; !*enable this to css resize*! */
    /* overflow: auto; */
}

/* .popup-annotations{
  border: 1px solid #d3d3d3!important;
}  */
.popup-header {
    /* padding: 10px; */
    cursor: pointer;
    z-index: 8;
    background-color: #2196f3;
    /* color: #fff; */
}
.select-tooth-table td {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #dddddd;
}

.font-size-14 {
    font-size: 14px;
}
.y-scroll {
    overflow-y: scroll;
}
.word-break {
    /* word-break: break-word; */
    hyphens: auto;
}

.overflow-x {
    overflow-x: scroll;
}

.legends ul {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    list-style-type: none;
    padding: 0px 0px;
    margin: 0px -15px -2px 0px;
}

.legends ul li {
    flex-wrap: wrap;
    margin-right: 15px;
    margin-bottom: 2px;
}

.toggle-teeth-button {
    padding: -12px;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
}
.teeth-chart-cotainer {
    overflow-x: auto;
    margin: 5px 0px;
}

.select-tooth-table {
    border-collapse: separate;
    border-spacing: 1;
}

.teeth-chart-cotainer::-webkit-scrollbar {
    height: 8px;
}
.teeth-chart-cotainer::-webkit-scrollbar-track {
    background-color: #dddddd;
    border-radius: 10px;
}

.teeth-chart-cotainer::-webkit-scrollbar-thumb {
    background: #a2a2a4;
    border-radius: 10px;
}

.selected-teeth {
    background-color: #53b0fc;
    color: white;
    font-weight: bold;
}

.ai-observation-container {
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ai-observation-container::-webkit-scrollbar {
    width: 0px;
}
.date {
    font-family: "Roboto Condensed", sans-serif;
    /* font-weight: 700; Bold */
}
/* .ai-observation-container::-webkit-scrollbar-thumb {
background: #adadae;
border-radius: 10px;
} */
